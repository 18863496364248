.home {
  background-image: url("../Assets/Image/swip-quiz.png") ;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #f8c333;
}
.swip-logo {
  display: flex;
  align-items: center;
  justify-content: center;
img{
  cursor: pointer;
}
}
.swip-title {
  margin-top: 25.6rem;
  margin-bottom: 4rem;
  display: flex;
  align-items: center;
  padding: 3rem 1.5rem 7rem;
  justify-content: center;
  flex-direction: column;
  .title {
    font-size: 1.5rem;
    font-weight: 500;
    color: #fff !important;
  }
  .sub-title {
    color: #fff;
    padding-top: 10px;
    font-weight: 500;
    text-align: center;
    font-size: 1.1rem;
  }
  .btn-play-now {
    font-size: 18px !important;
    font-weight: 700;
    justify-content: flex-start;
    box-shadow: 0 8px 15px rgb(0 0 0 / 10%);
    text-align: left;
    border: transparent;
    min-width: 200px;
    background-color: #215d88;
    color: #fff;
    border-width: 1px;
    cursor: pointer;
    justify-content: center;
    padding: 16px;
    text-align: center;
    white-space: nowrap;
    border-radius: 25px;
    margin-top: 20px;
    width: 100%;
  }

}
header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}
.fix-w {
  height: 100%;
  width: 100%;
  max-width: 500px;
  min-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  margin: 0 auto;
  //background: linear-gradient(to bottom, #f9b970, #f54048);
}



.language {
  background-color: #6c8c4a;
  height: 40px;
  width: 100px;
  border-radius: 50%;
  text-align: center;
  line-height: 2;
}

.dropdown-toggle.menuitem::after {
  display: none;
}

.dropdown.cus .dropdown-menu {
  // min-width: 5rem;
  // max-width: 5.5rem;
  // width: 300px;
  // padding: 0;
}

.dropdown-item {
  // text-align: center;
  display: flex ;
  flex-direction: row;
  justify-content: space-between;
  margin:auto;
}

.dropdown-item:hover {
  background-color: pink;
}
.mr-15
{
  margin-right:2rem !important;
}


#langWrapper div {
  position: absolute;
  bottom: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.position
{
  position: absolute;
  margin-top: -10rem;
}
.hidden
{
  display: none;
}
.font-xl
{
  font-size: 25px;
}

.algin
{
  margin: auto;
  font-size:.75rem;
  color:black;
}
.btn {
  background-color: #daa09c;
  border-color: #daa09c;
  margin: 10px auto;
  color: black;
  width: 200px;
  &.disabled {
    color: #fff;
    // background-color: $theme-color;
  }
}
.language {
  background-color: #6c8c4a;
  height: 40px;
  width: 100px;
  border-radius: 50%;
  text-align: center;
  line-height: 2;
}

.dropdown-toggle.menuitem::after {
  display: none;
}

.dropdown.cus .dropdown-menu {
  // min-width: 5rem;
  // max-width: 5.5rem;
  width: 200px;
  padding: 0;
}


.dropdown-item:hover{
  background-color: pink;
  cursor: pointer;
}


.flag{
  position: relative;
}

a:hover{
  text-decoration: none;
  outline: none;
}
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
// .container input {
//   position: absolute;
//   opacity: 0;
//   cursor: pointer;
//   height: 0;
//   width: 0;
// }

// input[type="checkbox"]:after {
//   position: relative;
//   display: block;
//   left: -2px;
//   top: -3px;
//   width: 24px;
//   height: 24px;
//   border:2px solid gray;
//   content: "";
//   background: white;
//   // background-image: linear-gradient(135deg, #b1b6be 0%, #fff 100%);
//   background-repeat: no-repeat;
//   background-position: center;
//   border-radius:50%;
// }
// input[type="checkbox"]:checked:after {
//   background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAQAAABuW59YAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAB2SURBVHjaAGkAlv8A3QDyAP0A/QD+Dam3W+kCAAD8APYAAgTVZaZCGwwA5wr0AvcA+Dh+7UX/x24AqK3Wg/8nt6w4/5q71wAAVP9g/7rTXf9n/+9N+AAAtpJa/zf/S//DhP8H/wAA4gzWj2P4lsf0JP0A/wADAHB0Ngka6UmKAAAAAElFTkSuQmCC"),
//     linear-gradient(135deg, #fff 0%, #fff 100%);
// }

// input[type="checkbox"]:not(:disabled):checked:hover:after {
//   background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAQAAABuW59YAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAB2SURBVHjaAGkAlv8A3QDyAP0A/QD+Dam3W+kCAAD8APYAAgTVZaZCGwwA5wr0AvcA+Dh+7UX/x24AqK3Wg/8nt6w4/5q71wAAVP9g/7rTXf9n/+9N+AAAtpJa/zf/S//DhP8H/wAA4gzWj2P4lsf0JP0A/wADAHB0Ngka6UmKAAAAAElFTkSuQmCC"),
//     linear-gradient(135deg, #fff 0%, #fff 100%);
// }
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #25023b;
  border: 2px solid white;
  border-radius: 3px;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #25023b;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.container input:focus {
  outline: none; /* no outline - for most browsers */
  box-shadow: none; /* no box shadow - for some browsers or if you are using Bootstrap */
}
.container input:checked ~ .checkmark {
  background-color: white;
}

.lang-flag
{
  width:30px;
  height:20px;
  border-radius: 5px;
  margin:auto;
  justify-content: flex-end !important;
}
#flag1, #flag2,#flag3{
  border: 2px solid white;
  border-radius: 50%;
  margin-right:2px;
}

#flag2{
  margin-left: -2px;
}

#langWrapper div{
  position: absolute;
  bottom: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: relative;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.5;
}
.main-btns-div
{
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.lng-btn
{
  background-color: #daa09c;
  border-color: #daa09c;
  border: none;
  border-radius: 50px;
  padding: 0.5rem 1rem;
  margin-left:0.5rem;
  margin-right:0.5rem;
}
.overlay.show {
  position: fixed;
  opacity: 1;
}

.title_lang{
  position: absolute;
  margin-top: 28rem;
  left:6rem
}
