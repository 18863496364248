// .navbar {
//     background-color: rebeccapurple;
//     padding: 5px 10px;
//     color: white;
//     font-size: 20px;
//     cursor: pointer;
//     outline: none;
//     border: none;
//     position: absolute;
//     top: 1rem;
//     right: 2rem;
//     border-radius: 2rem;
//     transition: all 0.5s ease-in-out;
//   }
.navigation img{
    margin: 10px
}
//   .navigation ul{
//     list-style: none;
//     padding: 5px;
//   }
  .navigation div{
    list-style: none;
    margin-top: 1rem;
  }
   a{
    margin-bottom: 20px;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: "Lato", sans-serif;
    color:#ffffff
  }
   a.active {
    display: block;
    padding: 15px;
    text-decoration: none;
    color: #ffffff;
    font-weight: 800;
    background-color: #205d88;
    margin: 0 20px 0 0;
  
    border-top-left-radius: 500px 20px;
   border-top-right-radius: 500px 20px;
    // border-radius: 20px 0px 0px 20px ;
  }

  .active-sidebar{
    background: #205d88;
    // color:white;
    // padding-top: 10px;
    // padding-bottom: 10px;
    border-radius: 50px 0px 0px 50px;
    ;
    width: 100%;
  }

  .list{
    padding: 8px 20px;
    text-decoration: none;
    font-size: 1.2rem;
    color: #ffffff;
    display: block;
    transition: 0.3s;
    font-weight: 600;
    margin-left: 24px;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }



  