  .App {
    max-width: 500px;
    margin: 0 auto;
    overflow: hidden;
    min-height: 100vh;
    background-color: #f8c333;
  }
  .main {
    background-color: #f8c333;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    max-height: 100vh;
    overflow: hidden;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .card_container {
    position: fixed;
    top: 5rem;
    max-width: 500px;
    height:92%;
    width: 100%;
    padding-top: 1rem;
    overflow: hidden;
  }

  .card{ 
    width: 92%;
    height: auto;
    border: none;
    // position: fixed;
    // border-radius: 0.5rem;
    align-items: center;
    border-left: 10px solid #205d88;
    // margin: 1rem auto;
    padding: 1rem;
    top:100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;}
  :host {
    width     : 100%;
    height    : 100%;
    overflow  : hidden;
    position  : absolute;
    left      : 0;
    top       : 0;
    //background: #f9b970;
    background-color: #FDCB2E;
    /* fallback for old browsers */
    //background: -webkit-linear-gradient(to bottom, #f9b970, #f54048);
    /* Chrome 10-25, Safari 5.1-6 */
    //background: linear-gradient(to bottom, #f9b970, #f54048);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  
  .info-container {
    position: fixed;
    top     : 5rem;
    max-width: 500px;
    height  : 88%;
    width   : 100%;
    overflow-y: scroll;

    // padding : 1rem;
  }
  
  .card_menu {
    width        : 92%;
    height       : 92%;
    background-color: white;
    border       : none;
    padding : .5rem;
    border-radius: 1rem;
    overflow: scroll;
  }
  

  .loader-container {
    background: rgba(0, 0, 0, 0.834);
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 2px solid;
    border-color: #ffffff transparent #ffffff transparent;
    border-radius: 50%;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .Modal {
    position: absolute; 
    top: 0; 
    left: 0;
    z-index: 2050;
    width: 100%;
    height: 100vh;
    background-color:rgba(0,0,0,0.5); 
    display: flex;
    opacity: 1.9; 
    //transition: opacity .5s linear;
    overflow-x: hidden; 
    overflow-y: hidden;
  }
  .modal_fade{
    transition: transform .3s ease-out;
      transform: translate(0,0);
  } 