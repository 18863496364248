.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;

  .header_icon {
    span {
      margin: 0 10px;

      &.logo_main {
        margin-right: 40px;
      }

      img {
        cursor: pointer;
        display: inline-block;
      }
    }

    &:first-child {
      span {
        margin-left: 0;
      }
    }

    &:last-child {
      span {
        margin-right: 0px;
      }
    }

    span {
      .cross {
        z-index: 1111111;
      }
    }
  }
   .header_icon_Swip 
    span {
      margin: 0 10px;

      &.logo_main {
        cursor: pointer;
        margin-left: 7rem;
      }

      img {
        display: inline-block;
      }
    }
  
}

.hideNav {
  position: absolute;
  transform: translateX(200%);
  display: none;
}

.showNav {
  display: block;
  height: 100vh;
  position: absolute;
  z-index: 3000;
  transform: translateX(60%);
  overflow: hidden;
  .close{
      position: absolute;
      top: 24px;
      border-radius: 50%;
      font-size: 28px;
      padding: 0;
      width: 48px;
      height: 48px;
      text-align: center;
      line-height: 48px;
  }
}

.navbar {
  transition: 0.5s ease-in-out;
  z-index: 16;
  position: fixed;
  right: 32%;
  width: 295px;
  top:0
}

@media (min-width: 760px) {
  .navbar {
    transition: 0.5s ease-in-out;
    z-index: 1;
    position: fixed;
    right: 19%;
    width: 295px;
  }
  .header  .header_icon_Swip 
    span {
      margin: 0 10px;

      &.logo_main {
        cursor: pointer;
        margin-left: 9rem;
      }

      img {
        display: inline-block;
      }
    }
  
}

@media (min-width: 900px) {
  .navbar {
    transition: 0.5s ease-in-out;
    z-index: 19;
    position: fixed;
    right: 42%;
    width: 295px;
  }
  .header  .header_icon_Swip 
    span {
      margin: 0 10px;

      &.logo_main {
        cursor: pointer;
        margin-left: 9rem;
      }

      img {
        display: inline-block;
      }
    }
  
}
