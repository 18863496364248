.buy-more-card {
  // background-color: #fff;
  // border-left: solid 3px #205D88;
  // width: 20vw;
  // height: 10vw;
  // border-radius: 10px;
  border-left: 10px solid #a661eb;
  margin: 1rem auto;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 100%;
  height: auto;
  border-radius: 0.5rem;

  .packinfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h4{
        margin: 0;
        text-align: center;

    }
    p{
        font-size: 2rem;
        margin: 10px;
        color:#6c757d!important
    }
  }
  .buy-more-chances-btn{
    color: #fff;
    background-color: #a661eb;
    /* border-color: #205d88; */
    font-size: 1.25rem;
    border-radius: 1.3rem;
    padding: 0.8rem;
    border: #fff;
    cursor: pointer;
  }
  .not-now{
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    font-size: 18px;
    font-weight: 500;
    border:none;
    padding-top:10px
  }
}
