.swipe {
  position: fixed;
  display: flex;
  max-width: 480px;
  overflow: hidden;
  width: 100%;
  height: calc(100% - 170px);
  flex-direction: column;
  -ms-flex-direction: column;
  justify-content: space-between;
  z-index: 11;
  .swip_cards {
    height: 352px;
    width: 270px;
    background-color: #fff;
    z-index: 22;
    will-change: transform;
    text-align: center;
    overflow: hidden;
    position: absolute;
    top: calc(46% - 270px / 2);
    left: calc(56% - 362px / 2);
    z-index: 3;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
    border-radius: 10px;
    p {
      font-size: 1rem;
      color: #000;
      z-index: 11 !important;
      margin-bottom: 1.5rem;
      font-weight: 500;
      line-height: 1.2;
    }
    img {
      height: 100%;
    }
  }
}
.btn-RL {
  position: absolute !important;
  top: 650px !important;
  left: 25% !important;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  .left {
    background-color: #000;
    opacity: 0.3;
    top: 10px;
    padding-top: 6px;
    border-radius: 50px;
  }
  .right {
    background-color: #000;
    opacity: 0.3;
    top: 10px;
    padding-top: 6px;
    border-radius: 50px;
  }
}
.swip_title {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  background-color: black;
  opacity: 0.3;
  border-radius: 20px;
  padding: 8px;
  margin-top: 0;
  margin-bottom: 2rem;
}
.counter {
  position: absolute;
  top: 600px;
  left: 42%;
  background-color: #000;
  opacity: 0.3;
  color: #fff;
}
.swipe-cards-background::before {
  content: "";
  width: 270px;
  height: 342px;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotate(6deg) translate(-50%, -50%);
  border-radius: 1rem;
  z-index: 1;
}
.swipe-cards-background::after {
  content: "";
  width: 270px;
  height: 342px;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotate(-6deg) translate(-50%, -50%);
  border-radius: 1rem;
  z-index: 1;
}
.info-title {
  padding: 0.5rem;
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
}
.info-subtitle {
  padding-bottom: 1rem;
  text-align: center;
  font-weight: 500;
  font-size: 1.2rem;
}
.info-info {
  font-size: 0.8rem;
  line-height: 3.5;
  background-size: 120% 180%;
}
.info-text {
  padding: 0.5rem;
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
}
.info-hr {
  margin-bottom: 1rem;
  border: 0;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.playlater-text {
  color: #721c24;
  background-color: #f8d7da;
  border-color: solid 1px #f291b8;
  text-align: start;
  margin: 5px;
  font-weight: 500;
  padding: 2rem 0.5rem 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
